const LANGUAGE = 'language'
//zh
//en


function setLanguageFromLocal(language){
    localStorage.setItem(LANGUAGE,language)
}

function getLanguageFromLocal(){
    const language = localStorage.getItem(LANGUAGE)
    return language
 
}

function removeLanguageFromLocal(){
    localStorage.removeItem(LANGUAGE)
}

export{
    setLanguageFromLocal,
    getLanguageFromLocal,
    removeLanguageFromLocal
}
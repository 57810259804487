import './about.css'
import { useNavigate } from 'react-router-dom'
import { useState } from "react"
import { useTranslation } from "react-i18next";
import "../../i18n/I18n";
import { postIssue } from '../../apis/issue';
import { newAlert } from '../../utils/alert';

const About = () =>{

    const navigate = useNavigate()
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();

    async function issueClick(){
        const text = document.getElementById('input').value;
        if(text){

            var issue = {};
            issue.text = text;


            const res = (await postIssue(JSON.stringify(issue))).data
            console.info('issueClick')
            console.info(res)
            if (res) {
                console.info(res)
                newAlert(res.message)
            }
        }
    }


    return (
        <div className="about-body">
            <div className="about-returnArrow" onClick={()=>{navigate('/')}}>
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
            </div>


            <div className="about-label" >
                <div className= "about-labelTitle">{t("about")}</div>
            </div>
            <div className="about-main">


                <div className="about-logo">
                    <img className="about-logoImg" src="./ff14chat.png"/>
                </div>

                <div className="about-titleArea about-labelTitle" >
                    <div>{t("version")}</div>
                    <div> 1.0.0</div>
                    <div className="about-space">|</div>
                    <a href="#/changelog">{t("changelog")}</a>
                </div>

                {/* <div className="about-titleArea about-labelTitle">
                    <a href="#/sponsors">{t("sponsors")}</a>
                </div> */}

                <div className="about-issueArea" id="issueArea" style={{ height: !isOpen ? '10%' : '40%' }} onClick={()=>{setOpen(true)}}>

                    { !isOpen && <button className="about-saveButton" id="issue">{t("issue")}</button>}

                    { isOpen &&  <div className="about-submit" id="submit">
                        <div className="about-textArea">
                            <textarea className="about-input" placeholder={t("issue")} id="input"></textarea>
                        </div>
                        <div className="about-return">
                            <button className="about-saveButton" id = "returnButton" onClick={()=>{issueClick()}}>{t("submitissue")}</button>
                        </div>
                    </div>}
                   
                </div>
            </div>
    </div>
    )
}

export default About
import { useEffect,React,useState,createContext } from "react"
import { heartbeatAPI } from "../../apis/user"
import { getUserFromLocal } from "../../utils"
import { useNavigate } from 'react-router-dom'
import { newAlert } from "../../utils/alert"

import IndexGame from "./indexGame"
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';


const LocalUserContext = createContext('null')
const Index = () =>{

    const [localUser,setLocalUser] = useState({});
    const navigate = useNavigate()
    const [client, setClient] = useState(null);

    useEffect(() => {

        const local = getUserFromLocal()
        setLocalUser(local)
        if(!local){
            navigate('/login')
        }

        const stompClient = new Client({
          // 使用SockJS协议
          webSocketFactory: () => new SockJS('http://127.0.0.1:8091/ws'),
          connectHeaders: {
            Authorization: local.token
        },
        });


        // 连接成功回调函数
        stompClient.onConnect = (frame) => {
          console.log('Connected:', frame);

          // 订阅一个目标，收到消息时触发回调
          stompClient.subscribe('/topic/public', (message) => {
            console.log('Received message:', JSON.parse(message.body));
          });
        };


        // 连接错误回调函数
        stompClient.onStompError = (frame) => {
          console.error('Broker reported error:', frame.headers['message']);
          console.error('Additional details:', frame.body);
        };

        stompClient.activate();
        setClient(stompClient);
        console.info('建立连接')

        return () => {
          stompClient.deactivate();
        };

    },[]); 

    const sendMessage = (msg) => {
      if (client && client.connected) {
        const payload = { type: 'CHAT' , content: 'xxxcontent' , sender:'xxxsender' };
        client.publish({
          destination: '/app/chat.sendMessage', // 发送目标的路径
          body: JSON.stringify(payload), // 消息内容
        });
      }
    };

    //心跳 
    // useEffect(() => {
    //     const intervalId = setInterval(async () => {
    //         const res = (await heartbeatAPI()).data
    //         if(res){
    //             if(res.code==200){
    //                 if(localUser.id !== res.data.id){
    //                     newAlert('心跳账户异常');
    //                     navigate('/login')
    //                 }
    //             }else if(res.code==401){
    //                 newAlert('心跳异常');
    //                 navigate('/login')
    //             }
    //         }else{
    //             console.info("heartbeat error")
    //         }
    //         console.info('sendmessage')
    //         sendMessage()
    //     }, 10000); 
        
    //     return () => clearInterval(intervalId);
    // }, [localUser,client]); 

    console.info('Index 组件刷新');
    return (
        <LocalUserContext.Provider  value = {localUser}>
            <IndexGame />
        </LocalUserContext.Provider>
    )}

export  { LocalUserContext }
export default Index;

import { request } from "../utils";

const msgPath = 'msg/msg/v1/';
const systemMsgPath = 'msg/systemMsg/v1/system'
const reportMsgPath = 'msg/reportMsg/v1/';



function getMsgAPI(serverId){
    return request({
        url: msgPath + serverId,
        method: 'GET'
    })
}

function getMsgByMaxIdAPI(serverId,maxId){
    return request({
        url: msgPath + serverId +'/' + maxId,
        method: 'GET'
    })
}

function getSystemMsgAPI(){
    return request({
        url: systemMsgPath,
        method: 'GET'
    })
}

function postMsgAPI(json){
    return request({
        url: msgPath,
        method: 'POST',
        data: json,
    })
}
// {
//     "type": "",
//     "message": ""
//   }

function postReportMsgAPI(json){
    return request({
        url: reportMsgPath,
        method: 'POST',
        data: json,
    })
}
// {
//     "id": ""
//   }

export{ getMsgAPI, getMsgByMaxIdAPI, getSystemMsgAPI, postMsgAPI, postReportMsgAPI}
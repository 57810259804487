import { request } from "../utils";


const loginPath = 'user/user/v1/login';
const heartbeatPath = 'user/user/v1/heartbeat';
const registrPath = 'user/user/v1/register';
const getLoginUsers = 'user/user/v1/getLoginUser/';
const changeCharnamePath = 'user/user/v1/changeCharName';
const changeUsernamePath = 'user/user/v1/changeUserName';
const changePasswordPath = 'user/user/v1/changePassword';

function registerAPI(data){
    console.info('registerAPI')

    return request({
        url: registrPath,
        method: 'POST',
        data: data,
    })
}

function loginAPI(data){
    return request({
        url: loginPath,
        method: 'POST',
        data: data,
    })
}

function heartbeatAPI(){
    return request({
        url: heartbeatPath,
        method: 'POST'
    })
}

function getusersAPI(serverId){
    return request({
        url: getLoginUsers + serverId ,
        method: 'GET'
    })
}

function changeCharnameAPI(json){
    return request({
        url: changeCharnamePath,
        method: 'POST',
        data: json,
    })
}
// {
//     "oldName": "",
//     "newName": ""
//   }

function changeUsernameAPI(json){
    return request({
        url: changeUsernamePath,
        method: 'POST',
        data: json,
    })
}
// {
//     "oldName": "",
//     "newName": ""
//   }

function changePasswordAPI(json){
    return request({
        url: changePasswordPath,
        method: 'POST',
        data: json,
    })
}
// {
//     "oldPassword": "",
//     "newPassword": ""
//   }



export{ loginAPI,heartbeatAPI,registerAPI,getusersAPI,changeCharnameAPI,changeUsernameAPI,changePasswordAPI }
import { useTranslation } from "react-i18next";
import "../../../i18n/I18n";
import { useEffect,React,useState,useContext } from "react"
import { getusersAPI } from "../../../apis/user"
import { LocalUserContext } from "..";

const ListItem = ({ item }) => (
    <div className="user">{item.username}</div>
  );

function UserList(){
    const { t } = useTranslation();
      //获取用户
    const [userList , setUserList] = useState([]);
    const serverId = useContext(LocalUserContext).serverId;

    useEffect(() => {
      const intervalId = setInterval(async () => {
          const res = (await getusersAPI(serverId)).data
          if(res && res.code==200 &&res.data.length > 0){
              setUserList(res.data)
          }
      }, 2000); 
      
      return () => clearInterval(intervalId);
  }, [userList,serverId]); 

    return (
      <div className="users" id="users">
        <div className = "user system-user">{t("online")}</div>
          <div className="htmlpage-row" id="usersOnline">
              
                <div>
                    {userList
                    .sort((a, b) => a.username - b.username)
                    .map(item => (
                        <ListItem key={item.userid} item={item} />
                    ))}
                </div>
          </div>
      </div>
    )
}

export default UserList


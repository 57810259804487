import axios from "axios";
import { getUserFromLocal } from "./localstorage/user";
import { getLanguageFromLocal } from "./localstorage/language";


const request = axios.create({
    baseURL: 'https://ff14chat.com/ff14chat/',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    }
})

request.interceptors.request.use((config) =>{
    if(config.method === 'post' && config.url !== 'user/user/v1/login' && config.url !== 'user/user/v1/register'){

        const user = getUserFromLocal();
        if(user && user.token){

            config.headers.Authorization = user.token;
        }
    }


    if(getLanguageFromLocal() ==='zh'){
        config.headers['Accept-Language'] = 'zh-CN';
    }else{
        config.headers['Accept-Language'] = 'en-US';
    }
    


    return config
},(error) => {
    return Promise.reject(error)
})

// request.interceptors.response.use((response) =>{
//     //2xx
//     return response.data
// },(error) => {
//     return error
// })

export { request }
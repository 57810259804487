import './settings.css'
import { useNavigate } from 'react-router-dom'
import { useState,useEffect } from "react"
import { useTranslation } from "react-i18next";
import "../../i18n/I18n";
import { getUserFromLocal } from '../../utils';
import worldData from '../../world.json'
import { getEnableNamazuFromLocal,setEnableNamazuFromLocal } from '../../utils/index';
import { removeUserFromLocal } from '../../utils';
import { getBindChannalFromLocal,setBindChannalFromLocal } from '../../utils/index';
import { getNamazuChannelFromLocal,setNamazuChannelFromLocal } from '../../utils';

const Settings = () =>{

    const navigate = useNavigate()
    const { t } = useTranslation();
    const [localUser,setLocalUser] = useState({});
    const [enableNamazu, setEnableNamazu] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [bindChannal ,setBindChannal] =useState(['0','0','0','0'])
    const [namazuChannelList,setNamazuChannelList] = useState([false,false,false])

    //获取当前用户
    useEffect(() => {
        const local = getUserFromLocal()
        setLocalUser(local)
        if(!local){
            navigate('/login')
        }
        setEnableNamazu(getEnableNamazuFromLocal()==='true');

        const localBindChannal = getBindChannalFromLocal()
        if(localBindChannal){
            setBindChannal(localBindChannal)
        }

        const localNamazuList = getNamazuChannelFromLocal()
        if(localNamazuList){
            setNamazuChannelList(localNamazuList)
        }
    },[]); 

    function changeEnableNamazu(){
        if(enableNamazu && enableNamazu===true){
            setEnableNamazu(false)
            setEnableNamazuFromLocal(false)
        }else{
            setEnableNamazu(true)
            setEnableNamazuFromLocal(true)
        }
        setRefresh(refresh+1)
    }



    function changeEnableChannel(type){
        console.info(type)
        if(enableNamazu && enableNamazu===true){
            let num = parseInt(type)
            if(namazuChannelList[num] && namazuChannelList[num]===true){
                namazuChannelList[num]=false
            }else{
                namazuChannelList[num]=true
            }
            setNamazuChannelList(namazuChannelList)
            setNamazuChannelFromLocal(namazuChannelList)
            setRefresh(refresh+1)
        }
    }


    function logout(){
        removeUserFromLocal()
        navigate('/login')
    }



    const ListItem = (props) => (

        <div className='settings-buttonGroup'>
            <button className={props.item === '0'? "settings-button-select":"settings-button"} onClick={()=>{buttonClick(props,'0')}}>None</button>
            <button className={props.item === '1'? "settings-button-select":"settings-button"} onClick={()=>{buttonClick(props,'1')}}>1</button>
            <button className={props.item === '2'? "settings-button-select":"settings-button"} onClick={()=>{buttonClick(props,'2')}}>2</button>
            <button className={props.item === '3'? "settings-button-select":"settings-button"} onClick={()=>{buttonClick(props,'3')}}>3</button>
            <button className={props.item === '4'? "settings-button-select":"settings-button"} onClick={()=>{buttonClick(props,'4')}}>4</button>
            <button className={props.item === '5'? "settings-button-select":"settings-button"} onClick={()=>{buttonClick(props,'5')}}>5</button>
            <button className={props.item === '6'? "settings-button-select":"settings-button"} onClick={()=>{buttonClick(props,'6')}}>6</button>
            <button className={props.item === '7'? "settings-button-select":"settings-button"} onClick={()=>{buttonClick(props,'7')}}>7</button>
            <button className={props.item === '8'? "settings-button-select":"settings-button"} onClick={()=>{buttonClick(props,'8')}}>8</button>
        </div>
      );

      function buttonClick(props,id){
        console.info(props.id)
        console.info(id)

        if(bindChannal[0] === id ||bindChannal[1] === id ||bindChannal[2] === id ){
            return 
        }

        bindChannal[props.id] = id
        setBindChannal(bindChannal)
        setRefresh(refresh+1)
        setBindChannalFromLocal(bindChannal)
        
      }


    return (
        <div className="about-body">
            <div className="about-returnArrow" onClick={()=>{navigate('/')}}>
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
            </div>


            <div className="about-label" >
                <div className= "about-labelTitle">{t("settings")}</div>
            </div>
            <div className="settings-main">

                <div className="settings-titleArea about-labelTitle" >
                    <div>{t("settings")}</div>
                    <br/>

                    <div className='settings-namazuGroup'>
                        <div className="settings-flex-row">
                            <div className='settings-label'>{t("enableNamazu")}</div>
                            <label className="filters-switch" >
                                <input type="checkbox" checked={ enableNamazu===true}
                                    onChange={()=>{ changeEnableNamazu()}} />
                                <span className="filters-slider round"></span>
                            </label>
                        </div>
                        <label>{t("namazutext")}</label>

                        <div className="settings-flex-row">
                            <label className='settings-label'>{t("enableRegion")}</label>
                            <label className="filters-switch" >
                                <input type="checkbox" checked={ enableNamazu===true && namazuChannelList[0]===true}
                                    onChange={()=>{ changeEnableChannel(0)}} />
                                <span className="filters-slider round"></span>
                            </label>
                        </div>
                        <div className="settings-flex-row">
                            <label  className='settings-label'>{t("enableTeam")}</label>
                            <label className="filters-switch" >
                                <input type="checkbox" checked={ enableNamazu===true && namazuChannelList[1]===true}
                                    onChange={()=>{ changeEnableChannel(1)}} />
                                <span className="filters-slider round"></span>
                            </label>
                        </div>
                        <div className="settings-flex-row">
                            <label  className='settings-label'>{t("enableTranslate")}</label>
                            <label className="filters-switch" >
                                <input type="checkbox" checked={ enableNamazu===true && namazuChannelList[2]===true}
                                    onChange={()=>{ changeEnableChannel(2)}} />
                                <span className="filters-slider round"></span>
                            </label>
                        </div>
                    </div>

                    <br/>
                    <div className="settings-namazuGroup">
                        <div >{t("bindchannel")}</div>
                        <div>
                            <div className="settings-flex-row">
                                <label  className='settings-label'>{t("regionchannal")}</label>  
                                <ListItem key={0} id={0} item={bindChannal[0]} />
                            </div>
                            <div className="settings-flex-row">
                                <label  className='settings-label'>{t("teamupchannal")}</label>
                                <ListItem key={1} id={1} item={bindChannal[1]} />
                            </div>
                            <div className="settings-flex-row">
                                <label  className='settings-label'>{t("translatechannal")}</label>
                                <ListItem key={2} id={2} item={bindChannal[2]} />
                            </div>
                        </div>
                        <label>{t("bindchanneltext1")}</label>
                        <label>{t("bindchanneltext2")}</label>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>

                

                <div className="settings-titleArea about-labelTitle" >
                    <div>{t("account")}</div>
                    <br/>

                    <div className="settings-namazuGroup">
                        <div><label>{t("username")}</label>{localUser.username}</div>
                        <div><label>{t("charname")}</label>{localUser.charname}</div>
                        <div><label>{t("servername")}</label> {
                                worldData[localUser.serverId]
                                ? worldData[localUser.serverId].name === worldData[localUser.serverId].name_en
                                    ? worldData[localUser.serverId].name
                                    : worldData[localUser.serverId].name +'  '+ worldData[localUser.serverId].name_en
                                :''
                            }
                        </div>
                        <button className="settings-saveButton" onClick={()=>{logout()}}>  {t("logout")}</button>
                    </div>
                    <br/>
                    
                
   
                </div>

                
            

                <div className="settings-titleArea about-labelTitle" >
                </div>
            </div>
       
    </div>
    )
}

export default Settings
const NAMAZUCHANNEL = 'namazu_channel'
//[false,false,false] 

function setNamazuChannelFromLocal(list){
    localStorage.setItem(NAMAZUCHANNEL,JSON.stringify(list))
}

function getNamazuChannelFromLocal(){
    const list = localStorage.getItem(NAMAZUCHANNEL)
    if(list){
        return JSON.parse(list)
    }
}

function removeNamazuChannelFromLocal(){
    localStorage.removeItem(NAMAZUCHANNEL)
}

export{
    setNamazuChannelFromLocal,
    getNamazuChannelFromLocal,
    removeNamazuChannelFromLocal
}
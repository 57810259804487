const USER = 'user'

function setUserFromLocal(user){
    localStorage.setItem(USER,JSON.stringify(user))
}

function getUserFromLocal(){
    const user = localStorage.getItem(USER)
    if(user){
        return JSON.parse(user)
    }
}

function removeUserFromLocal(){
    localStorage.removeItem(USER)
}

export{
    setUserFromLocal,
    getUserFromLocal,
    removeUserFromLocal
}
const ENABLENAMAZU = 'enable_namazu'

function setEnableNamazuFromLocal(flag){
    localStorage.setItem(ENABLENAMAZU,JSON.stringify(flag))
}

function getEnableNamazuFromLocal(){
    const flag = localStorage.getItem(ENABLENAMAZU)
    return flag
}

function removeEnableNamazuFromLocal(){
    localStorage.removeItem(ENABLENAMAZU)
}

export{
    setEnableNamazuFromLocal,
    getEnableNamazuFromLocal,
    removeEnableNamazuFromLocal
}
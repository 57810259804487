function parseTimestamp(timestamp){
    var time = parseInt(timestamp)
    var date = new Date(time);
    var hours = date.getHours().toString().padStart(2, '0'); 
    var minutes = date.getMinutes().toString().padStart(2, '0');
    var timeString = '[' + hours + ':' + minutes  + ']';
    
    return timeString
}

export {parseTimestamp}
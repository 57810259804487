import './alert.css'
import { useSearchParams } from 'react-router-dom';



const Alert = () =>{

    const [params] = useSearchParams()
    let data = params.get('data')

    return (
    <div className="body">
         <div className="label" id = "label">{data}</div>
    </div>)
}

export default Alert
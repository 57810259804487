import './black.css'
import { useTranslation } from "react-i18next";
import '../../i18n/I18n'
import { useState,useEffect } from "react"
import { getBlackListFromLocal,setBlackListFromLocal } from '../../utils/index';
import { useNavigate } from 'react-router-dom'



const Black = () =>{

    const { t } = useTranslation();
    const navigate = useNavigate()
    const [blackList, setBlackList] = useState([]);
    const [refresh,SetRefresh] = useState(0);

    useEffect(() => {

        //加载黑名单

        const blackObject = getBlackListFromLocal()
        if(blackObject){
            setBlackList(blackObject)
        }else{
            setBlackList([])
        }
    },[]); 

    function removeItemWithId(e) {
        const index = blackList.findIndex(item => item.userid == e.currentTarget.id);
    
        if(index !== -1) {
            blackList.splice(index, 1);
            setBlackList(blackList)
            SetRefresh(refresh+1)
            setBlackListFromLocal(blackList)
        } else {
            console.log('未找到 ID 为 ' + e.currentTarget.id + ' 的对象');
        }
    }

    const ListItem = ({ item }) => (
        <div className = "filter" id={item.userid} onClick={removeItemWithId}>
            <span className="filterSpan">
                {t("Exclude") }
            </span> 
            {item.username}
        </div>
    );

    return (
    <div className='black-body'>
        <div className="about-returnArrow" onClick={()=>{navigate('/')}}>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
        </div>

        <div className="black-label" >
            <div className= "black-labelTitle" >{t("blacklist")}</div>
        </div>

        <div className="black-main">

            <div className="black-titleArea" data-i18n="enableblacklist">{t("enableblacklist")}</div>
            <div className="black-filters" id="filters">
                {blackList.map(item => (
                            <ListItem key={item.userid} item={item} />
                ))}

            </div>
        </div>
    </div>
    )
}

export default Black
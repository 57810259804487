import { createHashRouter } from 'react-router-dom'
import Index from '../pages/index'
import About from '../pages/about'
import Alert from '../pages/alert'
import Black from '../pages/black'
import Filters from '../pages/filters'
import Login from '../pages/login'
import Region from '../pages/index/region'
import Team from '../pages/index/team'
import Translate from '../pages/index/translate'
import Sponspors from '../pages/sponsors'
import ChangeLog from '../pages/changeLog'
import Settings from '../pages/settings'

const router = createHashRouter([
    {
        path:'/',
        element:<Index />,
        children:[
            {
                index:true,
                element: <Region />
            },
            {
                path: 'region',
                element: <Region />
            },
            {
                path: 'team',
                element: <Team />
            },
            {
                path: 'translate',
                element: <Translate />
            }
        ]
    },    
    {
        path: '/about',
        element: <About />
    },
    {
        path: '/alert',
        element: <Alert />
    },
    {
        path: '/black',
        element: <Black />
    },
    {
        path: '/filters/:id',
        element: <Filters />
    },
    {
        path: '/login',
        element:<Login />
    },
    {
        path: '/sponsors',
        element:<Sponspors />
    },
    {
        path: '/changelog',
        element:<ChangeLog />
    },
    {
        path: '/settings',
        element:<Settings/>
    }
])


export default router;
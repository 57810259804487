import { useEffect,React,useState } from "react"
import UserList from "./UserList/UserList"
import './index.css'
import Navbar from './Navbar/Navbar'

import ChatWindow from "./chatwindow"


const IndexWindow = () =>{

    const [userListOpen, setuserListOpen] = useState(true);

    return (
        <div className="index">
            <div className="main" id="main" style={{ width: userListOpen ? '85%' : '100%' }}>
                <Navbar userListOpen ={userListOpen} setuserListOpen={setuserListOpen} />
               <ChatWindow/>
            </div>
            {userListOpen && <UserList/>}
        </div>
)}

export default IndexWindow
import './changeLog.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import "../../i18n/I18n";

const ChangeLog = () =>{

    const navigate = useNavigate()
    const { t } = useTranslation();

    return (
        <div className="about-body">
            <div className="about-returnArrow" onClick={()=>{navigate('/')}}>
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
            </div>


            <div className="about-label" >
                <div className= "about-labelTitle">{t("changeLog")}</div>
            </div>

            <div className="about-main">
                <div className="changelog-titleArea about-labelTitle" >
                    <h1>v1.0.0</h1>
                    <div>
                        Apr 17,2024
                    </div>
                    <div className='changelog-text'>
                        + Initial release
                    </div>
                </div>

                <div className="changelog-titleArea about-labelTitle" >
                </div>

            </div>
    </div>
    )
}

export default ChangeLog
const LASTLOGINNAME = 'lastLoginName'

function setLastLoginNameFromLocal(name){
    localStorage.setItem(LASTLOGINNAME,name)
}

function getLastLoginNameFromLocal(){
    const name = localStorage.getItem(LASTLOGINNAME)
    return name
}

function removeLastLoginNameFromLocal(){
    localStorage.removeItem(LASTLOGINNAME)
}

export{
    setLastLoginNameFromLocal,
    getLastLoginNameFromLocal,
    removeLastLoginNameFromLocal
}
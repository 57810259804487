import { request } from "../utils";

const issueUrl = 'user/issue/v1/issue';

function postIssue(json){
    console.info('postIssue')

    return request({
        url: issueUrl,
        method: 'POST',
        data: json,
    })
}

export{ postIssue }
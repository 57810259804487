import { createSlice } from "@reduxjs/toolkit";
import { getUserFromLocal,setUserFromLocal } from "../../utils";


//"id": "",
// "serverId": "",
// "charname": "",
// "username": "",
// "token": 
const userStore = createSlice({
    //数据状态
    name:'user',
    initialState:{
        user: getUserFromLocal() || ''
    },
    reducers:{
        setUser(state,action){
            state.user = action.payload
            setUserFromLocal(action.payload)
        }
    }
})



//异步
const getUser = () =>{

    return async (dispatch)=>{
        const res = localStorage.getItem('user');
    }
}

const {setUser} = userStore.actions
const usereducer = userStore.reducer
export { getUser,setUser }
export default usereducer



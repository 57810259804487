const BLACK_LIST = 'blackList'

//black.userid 
//black.username 

function setBlackListFromLocal(lists){
    localStorage.setItem(BLACK_LIST,JSON.stringify(lists))
}

function getBlackListFromLocal(){
    const lists = localStorage.getItem(BLACK_LIST)
    if(lists){
        return JSON.parse(lists)
    }
}

function removeBlackListFromLocal(){
    localStorage.removeItem(BLACK_LIST)
}

export{
    setBlackListFromLocal,
    getBlackListFromLocal,
    removeBlackListFromLocal
}
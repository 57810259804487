import { configureStore } from "@reduxjs/toolkit";
import userReducer from './modules/userStore'
import msgReducer from "./modules/msgStore";

const store = configureStore({
    reducer:{
        user: userReducer,
        msg: msgReducer
    }
})

export default store
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./en/translation.json";
import zhTranslation from "./zh/translation.json";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      zh: {
        translation: zhTranslation
      }
    },
    lng: "en", // 默认语言
    fallbackLng: "en", // 当当前语言的翻译缺失时回退到该语言
    interpolation: {
      escapeValue: false // react已经安全了
    }
  });

 export default i18n;

 
// import React from "react";
// import { useTranslation } from "react-i18next";
// import "./i18next";

// function App() {
//   const { t } = useTranslation();

//   return (
//     <div>
//       <h1>{t("welcome")}</h1>
//       <p>{t("hello")}</p>
//     </div>
//   );
// }

// export default App;
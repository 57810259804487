const FILTERS = 'filters'
//filters.region = []
//filters.team =[]
//filters.translate=[]

function setFiltersFromLocal(filters){
    localStorage.setItem(FILTERS,JSON.stringify(filters))
}

function getFiltersFromLocal(){
    const filters = localStorage.getItem(FILTERS)
    if(filters){
        return JSON.parse(filters)
    }
}

function removeFiltersFromLocal(){
    localStorage.removeItem(FILTERS)
}

export{
    setFiltersFromLocal,
    getFiltersFromLocal,
    removeFiltersFromLocal
}
import { Outlet } from "react-router-dom"
import { useEffect,React,useState,useContext } from "react"
import { getSystemMsgAPI } from "../../../apis/msg"
import { getMsgAPI,getMsgByMaxIdAPI } from "../../../apis/msg"
import { useSelector,useDispatch } from "react-redux"
import { setRegion,setTeam,setTranslate } from "../../../store/modules/msgStore"
import { getEnableNamazuFromLocal } from '../../../utils/index';
import { PostNamazuGame } from "../../../utils/namazu"
import { parseTimestamp } from "../../../utils/time"
import { useTranslation } from "react-i18next";
import { getNamazuChannelFromLocal } from '../../../utils';
import { LocalUserContext } from ".."

function ChatWindow(){
    const { region,team,translate } = useSelector(state => state.msg)
    const [maxestId,setMaxertId] = useState(0)
    const dispatch = useDispatch()
    const [enableNamazu, setEnableNamazu] = useState(false);
    const { t } = useTranslation();
    const [namazuChannelList,setNamazuChannelList] = useState([false,false,false])
    const serverId = useContext(LocalUserContext).serverId;

    // //获取系统消息
    // useEffect(() => {
    //     async function getMsgFirst() {

    //         const response = (await getSystemMsgAPI()).data;
    //         if(response.code == 200){
    //         parseMsg(response.data,true)
    //         }

    //         const responseMsg = (await getMsgAPI(serverId)).data;
    //         if(responseMsg.code == 200){
    //             parseMsg(responseMsg.data,false)
    //         }
    //     }

    //     getMsgFirst();

    //     setEnableNamazu(getEnableNamazuFromLocal()==='true');

    //     const localNamazuList = getNamazuChannelFromLocal()
    //     if(localNamazuList){
    //         setNamazuChannelList(localNamazuList)
    //     }
    //     },[]); 
    
    //   //获取消息
    // useEffect(() => {
    //     const intervalId = setInterval(async () => {
    //         const res = (await getMsgByMaxIdAPI(serverId,maxestId.toString())).data
    //         if(res.code == 200){
    //             console.info(res)
    //             parseMsg(res.data,false)
    //         }
    //     }, 2000); 
        
    //     return () => clearInterval(intervalId);
    // }, [maxestId,region,team,translate]);


    var newRegion;
    var newTeam;
    var newTranslate;

    function parseMsg(msgList,isAdmin=false){
        var msgObjectList = [];
    
        for (let element of msgList) {
            msgObjectList.push(JSON.parse(element));
        }
    
        msgObjectList.sort((a, b) => a.timestamp - b.timestamp);

        newRegion = region.slice();
        newTeam = team.slice();
        newTranslate = translate.slice();

        for (let element of msgObjectList) {
            if(isAdmin){
                element.isAdmin =true
                element.timestamp= Date.now();
            }else{
                element.isAdmin =false
            }
            if(element.type == '1'){
                printRegion(element)
            }else if(element.type == '2'){
                printTeam(element)
            }else if(element.type == '3'){
                printTranslate(element)
            }
            if(element.id){
                var bigint = BigInt(element.id);
                bigint = (bigint > maxestId) ? bigint : maxestId
                setMaxertId(bigint)
            }
        }
        if(newRegion.length>0  && newRegion.length !== region.length){
            dispatch(setRegion(newRegion))
        }
        if(newTeam.length>0 && newTeam.length !== team.length){
            dispatch(setTeam(newTeam))
        }
        if(newTranslate.length>0 && newTranslate.length !== translate.length){
            dispatch(setTranslate(newTranslate))
        }
    }

    function printRegion(element){
        var isExist=false

        newRegion.forEach(item => {
            if (element.id === item.id) {
                isExist = true
            }
        });

        if(!isExist){
            newRegion.push(element);
            
            if (newRegion.length > 200) {
                newRegion = newRegion.slice(-200);
            }
        }

        postToGame(0,element)
    }

    function printTeam(element){
        var isExist=false

        newTeam.forEach(item => {
            if (element.id === item.id) {
                isExist = true
            }
        });

        if(!isExist){
            newTeam.push(element);
            
            if (newTeam.length > 200) {
                newTeam = newTeam.slice(-200);
            }
        }
        postToGame(1,element)
    }

    function printTranslate(element){
        var isExist=false

        newTranslate.forEach(item => {
            if (element.id === item.id) {
                isExist = true
            }
        });

        if(!isExist){
            newTranslate.push(element);
            
            if (newTranslate.length > 200) {
                newTranslate = newTranslate.slice(-200);
            }
        }
        postToGame(2,element)
    }

    function postToGame(type,element){
        if(enableNamazu===true && namazuChannelList[parseInt(type)]===true){
            var text = '/e ';
            if(type ===0){
                text += '['+t("regionchannal") +  '] ';
            }else if(type ===1){
                text += '['+t("teamupchannal") +  '] ';
            }else if(type === 2){
                text += '['+t("translatechannal") +  ']' ;
            }
            text += parseTimestamp(element.timestamp) + ' ' + element.username + ':    ' + element.message

            PostNamazuGame(text)
        }
    }

    return (
        <div className="chatPage">
            <Outlet/>
        </div>
    )
}


export default ChatWindow
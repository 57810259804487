import { createSlice } from "@reduxjs/toolkit";



const msgStore = createSlice({
    //数据状态
    name:'msg',
    initialState:{
        region:  [],
        team: [],
        translate: []
    },
    reducers:{
        setRegion(state,action){
            state.region = action.payload
        },
        setTeam(state,action){
            state.team = action.payload
        },
        setTranslate(state,action){
            state.translate = action.payload
        }
    }
})

const {setRegion,setTeam,setTranslate} = msgStore.actions
const msgReducer = msgStore.reducer
export {setRegion,setTeam,setTranslate}
export default msgReducer



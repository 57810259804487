const BIND_CHANNAL = 'bind_channal'
//['0','0','0','0'] 

function setBindChannalFromLocal(binds){
    localStorage.setItem(BIND_CHANNAL,JSON.stringify(binds))
}

function getBindChannalFromLocal(){
    const binds = localStorage.getItem(BIND_CHANNAL)
    if(binds){
        return JSON.parse(binds)
    }
}

function removeBindChannalFromLocal(){
    localStorage.removeItem(BIND_CHANNAL)
}

export{
    setBindChannalFromLocal,
    getBindChannalFromLocal,
    removeBindChannalFromLocal
}
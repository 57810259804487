import { useParams } from 'react-router-dom';
import './filters.css'
import { useTranslation } from "react-i18next";
import "../../i18n/I18n";
import { useState,useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { getFiltersFromLocal,setFiltersFromLocal } from '../../utils/index';
import { newAlert } from '../../utils/alert';



const Filters = () =>{

    const params = useParams()
    const { t } = useTranslation();
    const [channalId, setChannalId] = useState(1);
    const [isInclude, setIsInclude] = useState(false);
    const [filtersList,setFiltersList]  = useState([]);
    const navigate = useNavigate()
    const [refresh,SetRefresh] = useState(0);

    useEffect(() => {
        console.info('params' + params.id)
        setChannalId(params.id)

        //加载filters
        const filtersObject = getFiltersFromLocal()
        if(filtersObject){
            if(params.id=='1'){
                if(filtersObject.region){
                    setFiltersList(filtersObject.region)
                }else{
                    setFiltersList([])
                }
            }else if(params.id=='2'){
                if(filtersObject.team){
                    setFiltersList(filtersObject.team)
                }else{
                    setFiltersList([])
                }
            }else if(params.id=='3'){
                if(filtersObject.translate){
                    setFiltersList(filtersObject.translate)
                }else{
                    setFiltersList([])
                }
            }else{
                console.info("error channalId " +params.id)
            }
            SetRefresh(refresh+1)
        }

    },[params]); 

    function inputClick(){
        var input = document.getElementById('input');

        if(input.value == ''){
            return;
        }
    
        if(filtersList.length >= 1){
            for (let element of filtersList) {
                if (element.text == input.value) {
                    newAlert('The content already exists in the current filter \n 当前过滤器已存在该内容');
                    return 0; 
                }
            }
        }
        
        var filter = {};
        filter.id = getNextUniqueId();
        filter.text = input.value;
        filter.isInclud = isInclude;
        filter.type = channalId;
        filtersList.push(filter)
        setFiltersList(filtersList)
        input.value = "";
        SetRefresh(refresh+1)
        saveFilters()
    }

    function getNextUniqueId() {
        if(filtersList.length == 0){
            return 0;
        }else if(filtersList.length == 1){
            return filtersList[0].id+1;
        }
        const ids = filtersList.map(e => e.id);
        const maxId = ids.length > 0 ? Math.max(...ids) : 0;
        return maxId + 1;
    }

    function removeItemWithId(e) {
        const index = filtersList.findIndex(item => item.id == e.currentTarget.id);
    
        if(index !== -1) {
            filtersList.splice(index, 1);
            setFiltersList(filtersList)
            SetRefresh(refresh+1)
            saveFilters()
        } else {
            console.log('未找到 ID 为 ' + e.currentTarget.id + ' 的对象');
        }
    }

    const ListItem = ({ item }) => (
        <div>
            <div className = "filters-filter" id={item.id} onClick={removeItemWithId}> 
            { !item.isInclud && <span className="filters-filterSpan">{t("Exclude") }</span> }
            {item.text}
            </div>
        </div>
    );

    function saveFilters(){
        const filtersObject = getFiltersFromLocal() || {}
  
        if(filtersList){
            if(channalId==='1'){
                filtersObject.region =filtersList
            }else if(channalId==='2'){
                filtersObject.team=filtersList
            }else if(channalId==='3'){
                filtersObject.translate=filtersList
            }

            setFiltersFromLocal(filtersObject)
        }

    }



    return (
        <div className='filters-body'>
            <div className="about-returnArrow" onClick={()=>{navigate('/')}}>
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
            </div>

            <div className="filters-label" >
                <div className= "filters-labelTitle">
                    
                    {
                        channalId==='1' ? t("regionchannalfilter") : channalId==='2' ? t("teamupchannalfilter") :  t("translatechannalfilter") 
                    }
                 </div>
            </div>
            <div className="filters-main">
                <div className="filters-inputArea">
                    <input type="text" className="filters-input" placeholder={t("filtertext")} id="input"/>
                    <button className="filters-inputButton" id = "inputButton" onClick={()=>{inputClick()}}>{t("add")}</button>
                </div>
                <div className="filters-includeArea">
                    <label className="filters-switch" >
                        <input type="checkbox" onChange={()=>{ isInclude ?setIsInclude(false):setIsInclude(true)}} />
                        <span className="filters-slider round"></span>
                    </label>
                    <div className="filters-includeLabel">{t("Include")}</div>
                </div>

                <div className="filters-titleArea">
                    {t("enablefilters")}
                </div>
                <div className="filters-filters" id="filters">
                    {filtersList.map(item => (
                        <ListItem key={item.id} item={item} />
                    ))}
                    {/* <!-- <div class = "filter">1231241234</div>
                    <div class = "filter">
                        <span class="filterSpan">
                        不包括
                        </span> 
                        1231241234
                    </div>
                    <div class = "filter">1231241234</div>
                    <div class = "filter">1231241234</div>
                    <div class = "filter">1231241234</div>
                    <div class = "filter">1231241234</div>
        --> */}
                </div>
            </div>
        </div>
    )
}



export default Filters
import { useEffect,React,useState } from "react"

import { parseTimestamp } from "../../utils/time"
import { PostNamazuGame } from "../../utils/namazu"
import { getEnableNamazuFromLocal } from '../../utils/index';
import { getBindChannalFromLocal } from '../../utils/index';

import { setRegion,setTeam,setTranslate } from "../../store/modules/msgStore"
import { useSelector,useDispatch } from "react-redux"
import { postMsgAPI } from "../../apis/msg"
import { useTranslation } from "react-i18next";

import IndexWindow from "./indexWindow"


const IndexGame = () =>{

    const [bindChannal ,setBindChannal] =useState(['0','0','0','0'])
    const [enableNamazu, setEnableNamazu] = useState(false);
    const { region,team,translate } = useSelector(state => state.msg)
    const dispatch = useDispatch()

    useEffect(() => {

        const localBindChannal = getBindChannalFromLocal()
        if(localBindChannal){
            setBindChannal(localBindChannal)
        }

        setEnableNamazu(getEnableNamazuFromLocal()==='true');

    },[]); 

    function postToGame(element){
        if(enableNamazu){
            var text = '/e ['+t("regionchannal") +  '] ';
            text += parseTimestamp(element.timestamp) + ' ' + element.username + ':    ' + element.message

            PostNamazuGame(text)
        }
    }

    //获取游戏发送消息
    useEffect(() => {
        startOverlayEvents();
        addOverlayListener('LogLine', async (data) => {
            getGameMsg(data)
        });

    },[bindChannal,region,team,translate]); 

    async function getGameMsg(data){

        if(data.line.length === 6 && data.line[2] >= 10 && data.line[2]<=17){
            var type = data.line[2] - 9;
            var message = data.line[4];
            console.info(data);
            if((bindChannal[0] && bindChannal[0] !== '0' && bindChannal[0] == type)||
            (bindChannal[1] && bindChannal[1] !== '0' && bindChannal[1] == type)||
            (bindChannal[2] && bindChannal[2] !== '0' && bindChannal[2] == type)){
                var msg={};
                msg.message = message
                msg.type = type;
                const response = (await postMsgAPI(JSON.stringify(msg))).data
                console.info(response);
                if(response.code == '200'){
                    systemMsg(response.data)
                }else{
                    systemMsg(response.message)
                }
            }
        }
    }

    function systemMsg(text){
        var msg = {};
        msg.id = Date.now();
        msg.message = text;
        msg.type = 1;
        msg.username = "admin"
        msg.isAdmin = true
        msg.timestamp= Date.now();
        console.info(region)
        const newRegion= region.slice();
        newRegion.push(msg)
        dispatch(setRegion(newRegion))
        const newTeam= team.slice();
        newTeam.push(msg)
        dispatch(setTeam(newTeam))
        const newTranslate= translate.slice();
        newTranslate.push(msg)
        dispatch(setTranslate(newTranslate))
        postToGame(msg)
    }


    function postToGame(element){
        if(enableNamazu){
            var text = '/e ['+t("regionchannal") +  '] ';
            text += parseTimestamp(element.timestamp) + ' ' + element.username + ':    ' + element.message

            PostNamazuGame(text)
        }
    }

    return (
        <IndexWindow />
)}

export default IndexGame